import React from 'react';
import Row from '../Row/Row';
import Section from '../Section/Section';
import constants from '@constants';
import { Link } from 'gatsby';

export default function Footer() {
  return (
    <footer>
      <Section modificator="dark">
        <Row>
          <div className="footer__container">
            <div className="footer__box">
              <address className="footer__item">
                <strong>Adres:</strong>
                <br />
                <a
                  href="https://goo.gl/maps/6KhCUtUSn2ReAmy56"
                  title="Adres: Zawadzkiego 2, Suwałki"
                >
                  ul. Ks. Jerzego Jana Zawadzkiego 2, lok. 012
                  <br />
                  16-400 Suwałki
                </a>
              </address>
              <p className="footer__item">
                <strong>Godziny otwarcia:</strong>
                <br />
                09:00 - 17:00
              </p>
              <p className="footer__item">
                <Link to="/polityka-prywatnosci" >Polityka prywatności</Link>
              </p>
            </div>
            <div className="footer__box">
              <p className="footer__item">
                <strong>Telefon:</strong>
                <br />
                <a
                  href={`tel: ${constants.PHONE}`}
                  title={`Numer telefonu: ${constants.PHONE}`}
                >
                  {constants.PHONE}
                </a>
              </p>
              <p className="footer__item">
                <strong>Email:</strong>
                <br />
                <a
                  href="mailto: biuro@sercefinansow.pl"
                  title="Adres e-mail: biuro@sercefinansow.pl"
                >
                  biuro@sercefinansow.pl
                </a>
              </p>
              <p className="footer__item">
                <strong>Facebook:</strong>
                <br />
                <a
                  href="https://www.facebook.com/finansekredytyubezpieczenia/"
                  title="Profil Facebook: /finansekredytyubezpieczenia/"
                >
                  /finansekredytyubezpieczenia/
                </a>
              </p>
            </div>
          </div>
        </Row>
      </Section>
    </footer>
  );
}
