import React from 'react';
import { func, bool, number } from 'prop-types';

function LoadMore({ loaded, total, setNextPage, nextEnabled }) {
  return (
    <div className="load-more__container">
      <button
        onClick={setNextPage}
        disabled={!nextEnabled}
        className="load-more__button"
      >
        Załaduj więcej postów ({loaded} z {total})
      </button>
    </div>
  );
}

LoadMore.propTypes = {
  loaded: number,
  total: number,
  setNextPage: func,
  nextEnabled: bool,
};

export default LoadMore;
