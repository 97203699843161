import React from 'react';
import { Link } from 'gatsby';

export default function BackButton() {
  return (
    <Link to="/" className="back-button">
      &lsaquo;
    </Link>
  );
}
